import React, { useState } from 'react';
import { Row, Col, Table, Button, Modal, Form, Input, DatePicker, Select, Spin, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useListPositionKill, useListSKill } from 'hook/useProfileEmployee';
import { apiAddProjectsInvolvedIn, apiUpdateProjectsInvolvedIn } from 'api/profileEmployee';
import styles from './style.module.scss';
import { HTTP_UNPROCESSABLE_ENTITY, HTTP_OK } from 'constants/StatusCode';
import { apiDeleteProjectsInvolvedIn } from 'api/profileEmployee';
import { IoIosAdd } from 'react-icons/io';
import projectIcon from 'assets/img/icons/project.png';
import AlertComponent from 'components/Alert';
import dayjs from 'dayjs';

const { Option } = Select;

const Project = (props) => {
  const { uProfileUser, isLoading, userId, refetch } = props;
  const [isOpenModalAdd, setOpenModalAdd] = useState(false);
  const [isOpenModalEdit, setOpenModalEdit] = useState(false);
  const [formSubmit, setFormSubmit] = useState({});
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [isShowAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');

  const positionData = useListPositionKill(formSubmit);
  const skillsData = useListSKill();
  const skills = skillsData?.data?.skills ? skillsData?.data?.skills : [];
  const skillsDev = skills?.filter((skill) => skill.position_id === 4);
  const positions = positionData?.data ? positionData?.data : [];

  const phaseType = [
    {
      id: 1,
      name: 'Định nghĩa yêu cầu',
    },
    {
      id: 2,
      name: 'Basic design',
    },
    {
      id: 3,
      name: 'Detail design',
    },
    {
      id: 4,
      name: 'Implement',
    },
    {
      id: 5,
      name: 'Test',
    },
    {
      id: 6,
      name: 'Vận hành/bảo trì ',
    },
  ];

  const industryType = [
    {
      id: 1,
      name: 'ERP & CRM',
      key: 'erp_crm',
    },
    {
      id: 2,
      name: 'Streaming',
      key: 'streaming',
    },
    {
      id: 3,
      name: 'Matching platform',
      key: 'matching_platform',
    },
    {
      id: 4,
      name: 'E-commerce',
      key: 'e_commerce',
    },
    {
      id: 5,
      name: 'Social network',
      key: 'social_network',
    },
    {
      id: 6,
      name: 'AI',
      key: 'ai',
    },
    {
      id: 7,
      name: 'Machine Learning',
      key: 'machine_learning',
    },
    {
      id: 8,
      name: 'Internet of Things',
      key: 'iot',
    },
    {
      id: 9,
      name: 'Blockchain',
      key: 'blockchain',
    },
    {
      id: 10,
      name: 'Game Development',
      key: 'game_development',
    },
    {
      id: 11,
      name: 'Cloud Computing',
      key: 'cloud_computing',
    },
    {
      id: 12,
      name: 'Cybersecurity',
      key: 'cybersecurity',
    },
    {
      id: 13,
      name: 'Sản xuất chế tạo',
      key: 'manufacturing',
    },
    {
      id: 14,
      name: 'Bán lẻ',
      key: 'retail',
    },
    {
      id: 15,
      name: 'Dịch vụ tài chính',
      key: 'financial_services',
    },
    {
      id: 16,
      name: 'Giáo dục',
      key: 'education',
    },
    {
      id: 17,
      name: 'Y tế',
      key: 'healthcare',
    },
    {
      id: 18,
      name: 'Khoa học công nghệ',
      key: 'science_and_technology',
    },
    {
      id: 19,
      name: 'Du lịch và giải trí',
      key: 'tourism_and_entertainment',
    },
    {
      id: 20,
      name: 'Nông nghiệp và thực phẩm',
      key: 'agriculture_and_food',
    },
    {
      id: 21,
      name: 'Xây dựng',
      key: 'construction',
    },
    {
      id: 22,
      name: 'Logistics',
      key: 'logistics',
    },
    {
      id: 23,
      name: 'Viễn thông',
      key: 'telecommunication',
    },
    {
      id: 24,
      name: 'Truyền thông và Giải trí',
      key: 'media_and_entertainment',
    },
    {
      id: 25,
      name: 'Bất động sản',
      key: 'real_estate',
    },
    {
      id: 26,
      name: 'Dịch vụ công',
      key: 'public_services',
    },
    {
      id: 27,
      name: 'Bảo hiểm',
      key: 'insurance',
    },
    {
      id: 28,
      name: 'Khác',
      key: 'other',
    },
  ];

  const handleInput = (changedValues, allValues) => {
    setFormSubmit(allValues);
  };

  const handleEditInput = (changedValues, allValues) => {
    setSelectedProject(allValues);
  };

  const onFinish = async () => {
    let response = {};
    const data = {
      ...formSubmit,
      start_date: moment(formSubmit.start_date).format('YYYY-MM-DD'),
      end_date: moment(formSubmit.end_date).format('YYYY-MM-DD'),
    };
    response = await apiAddProjectsInvolvedIn(data);
    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      setMessage('Vui lòng nhập đầy đủ thông tin');
      setShowAlert(true);
      setFormSubmit({
        ...formSubmit,
      });
      return;
    }
    if (response?.data?.status === HTTP_OK) {
      form.resetFields();
      setFormSubmit({});
      setOpenModalAdd(false);
      refetch();
    }
  };

  const handleEditSubmit = async () => {
    const endDate = selectedProject?.end_date ? moment(selectedProject?.end_date).format('YYYY-MM-DD') : null;
    let response = {};
    const data = {
      ...selectedProject,
      start_date: moment(selectedProject.start_date).format('YYYY-MM-DD'),
      end_date: endDate,
    };

    response = await apiUpdateProjectsInvolvedIn(projectId, data);

    if (response?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      setMessage('Vui lòng nhập đầy đủ thông tin');
      setShowAlert(true);
      setSelectedProject({
        ...selectedProject,
      });
      return;
    }
    if (response?.status === HTTP_OK || response?.status === 202) {
      setSelectedProject(null);
      setOpenModalEdit(false);
      refetch();
    }
  };

  const handleClickEdit = (project) => {
    const endDate = project?.end_date ? moment(project?.end_date) : null;
    setSelectedProject(project);
    setProjectId(project.id);
    editForm.setFieldsValue({
      ...project,
      start_date: moment(project?.start_date),
      end_date: endDate,
      skill_ids: project?.skills?.map((skill) => skill.id),
      phase_type: phaseType
        .map((phase) => {
          if (project?.phase_type?.includes(phase.id.toString())) {
            return phase.id;
          }
          return undefined;
        })
        .filter((item) => item !== undefined),
      industry: industryType
        .map((industry) => {
          if (project?.industry?.includes(industry.id.toString())) {
            return industry.id;
          }
          return undefined;
        })
        .filter((item) => item !== undefined),
    });
    setOpenModalEdit(true);
  };

  const handleDelete = async (projectId) => {
    const response = await apiDeleteProjectsInvolvedIn(projectId);
    if (response?.status === HTTP_OK || response?.status === 202) {
      refetch();
    }
  };

  const onCancel = () => {
    form.resetFields();
    setOpenModalAdd(false);
  };

  const projectData = uProfileUser?.completed_projects ? uProfileUser?.completed_projects : [];

  // if (typeof selectedProject?.industry === 'string') {
  //   selectedProject.industry = JSON.parse(selectedProject.industry);
  // }

  const disabledStartDate = (current) => {
    // Can not select days before today and days after end date
    return current && current > dayjs().endOf('day');
  };

  const disabledDateEndDate = (current) => {
    return current && current > dayjs().endOf('day');
  };

  return (
    <>
      <div className={styles.profileBoxInfo}>
        <Row className={styles.rowTitle}>
          <Col span={22}>
            <div className={styles.skillSheetTitle}>
              <img alt={projectIcon} className={styles.imgTitle} src={projectIcon} />
              Dự án đã làm
            </div>
          </Col>
          <Col span={2}>
            <div className={styles.editDeleteButton}>
              <IoIosAdd onClick={() => setOpenModalAdd(true)} />
            </div>
          </Col>
        </Row>

        {(isLoading || !userId) && (
          <p className="error-no-data">
            <Spin className="mt-3" />
          </p>
        )}
        {!isLoading && (
          <Row className={styles.rowTitle}>
            <Col span={24} className={styles.skillBox}>
              <Table
                dataSource={projectData}
                rowKey="id"
                pagination={false}
                scroll={{ x: 'max-content', y: 800 }}
                columns={[
                  {
                    title: 'Vị trí / giai đoạn',
                    dataIndex: 'position_id',
                    key: 'position_id',
                    render: (text, project) => (
                      <>
                        <p className="bold">
                          {positions.map((position) => {
                            if (position.id === project.position_id) return position.name;
                            return null;
                          })}
                        </p>
                        <p>
                          {moment(project.start_date).format('DD/MM/YYYY')} {' - '}
                          {project?.end_date ? moment(project?.end_date).format('DD/MM/YYYY') : 'Hiện tại'}
                        </p>
                        <p>
                          (
                          {moment(project?.end_date ?? dayjs().endOf('day')).diff(moment(project.start_date), 'months')}{' '}
                          tháng)
                        </p>
                      </>
                    ),
                  },
                  {
                    title: 'Quy mô',
                    dataIndex: 'scale',
                    key: 'scale',
                    width: 100,
                  },
                  {
                    title: 'Thông tin dự án',
                    dataIndex: 'name',
                    key: 'name',
                    width: 550,
                    render: (text, project) => (
                      <>
                        <p>- {project.name}</p>
                        {/* <p className="pl-10">{project.content}</p> */}
                        <p
                          className="pl-10"
                          dangerouslySetInnerHTML={{ __html: project.content.replace(/\n/g, '<br />') }}
                        ></p>
                        <p>- Kỹ thuật</p>
                        <p className="pl-10">
                          Ngôn ngữ lập trình: {project.skills.map((skill) => skill.name).join(', ')}
                        </p>
                        <p className="pl-10">Ngôn ngữ khác: {project?.other_skill}</p>
                        <p className="pl-10">Database: {project?.database}</p>
                        <p className="pl-10">Server OS: {project?.server}</p>
                        <p className="pl-10">Framework: {project?.framework}</p>
                      </>
                    ),
                  },
                  {
                    title: 'Giai đoạn tham gia',
                    dataIndex: 'phase_type',
                    key: 'phase_type',
                    render: (text, project) => (
                      <>
                        {phaseType
                          .filter((phase) => project?.phase_type.includes(phase.id.toString())) // Filter based on matching IDs
                          .map((phase) => (
                            <p key={phase.id}>- {phase.name}</p>
                          ))}
                      </>
                    ),
                  },
                  {
                    title: '',
                    key: 'actions',
                    width: 100,
                    fixed: 'right',
                    render: (text, project) => (
                      <>
                        <Button icon={<EditOutlined />} onClick={() => handleClickEdit(project)} />
                        <Popconfirm
                          title="Bạn chắc chắn muốn xoá ?"
                          onConfirm={() => handleDelete(project.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button icon={<DeleteOutlined />} />
                        </Popconfirm>
                      </>
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        )}
      </div>
      <Modal
        title="Dự án đã làm"
        visible={isOpenModalAdd}
        onCancel={() => onCancel(false)}
        // onOk={handleSubmit}
        width={700}
        footer={null}
        closable={true}
        maskClosable={true}
      >
        <Form form={form} onValuesChange={handleInput} layout="vertical" labelAlign={'right'} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Tên dự án"
                rules={[
                  { required: true, message: 'Vui lòng nhập' },
                  {
                    max: 225,
                    message: 'Input cannot exceed 225 characters!',
                  },
                ]}
              >
                <Input className="h-[40px]" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="industry" label="Lĩnh vực dự án" rules={[{ required: true, message: 'Vui lòng nhập' }]}>
                <Select
                  placeholder=""
                  className="h-[40px]"
                  mode="multiple"
                  maxTagCount="responsive"
                  showSearch
                  filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                >
                  {industryType.map((industry) => (
                    <Option key={industry.id} value={industry.id}>
                      {industry.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="start_date" label="Ngày bắt đầu" rules={[{ required: true, message: 'Vui lòng nhập' }]}>
                <DatePicker
                  disabledDate={disabledStartDate}
                  placeholder=""
                  format="YYYY-MM-DD"
                  onChange={(value) => setFormSubmit({ ...formSubmit, start_date: moment(value).format('YYYY-MM-DD') })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="end_date" label="Ngày kết thúc">
                <DatePicker
                  disabledDate={disabledDateEndDate}
                  placeholder=""
                  format="YYYY-MM-DD"
                  onChange={(value) => setFormSubmit({ ...formSubmit, end_date: moment(value).format('YYYY-MM-DD') })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="position_id"
                label="Vị trí đảm nhiệm"
                rules={[{ required: true, message: 'Vui lòng nhập' }]}
              >
                <Select placeholder="" className="h-[40px]">
                  {positions.map((position) => (
                    <Option key={position.id} value={position.id}>
                      {position.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="scale"
                label="Quy mô dự án"
                rules={[
                  { required: true, message: 'Vui lòng nhập' },
                  {
                    max: 225,
                    message: 'Input cannot exceed 225 characters!',
                  },
                ]}
              >
                <Input className="h-[40px]" placeholder="Số MM" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="skill_ids" label="Ngôn ngữ lập trình">
                <Select
                  mode="multiple"
                  placeholder=""
                  className="h-[40px]"
                  maxTagCount="responsive"
                  showSearch
                  filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                >
                  {skillsDev.map((skill) => (
                    <Option key={skill.id} value={skill.id}>
                      {skill.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="other_skill" label="Ngôn ngữ khác">
                <Input className="h-[40px]" placeholder="Hàn, Nhật,..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="database" label="Database">
                <Input className="h-[40px]" placeholder="Mysql, PostgreSQL, MongoDB,..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="server" label="Server OS">
                <Input className="h-[40px]" placeholder="Ubuntu, CentOS, Redhat,..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="framework" label="Framework" placeholder="">
                <Input className="h-[40px]" placeholder="NodeJs, ReactJs, Laravel,..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phase_type"
                label="Giai đoạn phát triển"
                rules={[{ required: true, message: 'Vui lòng nhập' }]}
              >
                <Select maxTagCount="responsive" mode="multiple" placeholder="" className="h-[40px]">
                  {phaseType.map((phase) => (
                    <Option key={phase.id} value={phase.id}>
                      {phase.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="content"
                label="Nội dung nghiệp vụ/ vai trò trong dự án"
                rules={[{ required: true, message: 'Vui lòng nhập' }]}
              >
                <Input.TextArea rows={7} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Button type="default" htmlType="reset" onClick={() => setOpenModalAdd(false)}>
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Chỉnh sửa dự án"
        visible={isOpenModalEdit}
        onCancel={() => setOpenModalEdit(false)}
        // onOk={handleEditSubmit}
        width={700}
        footer={null}
        closable={true}
        maskClosable={true}
      >
        <Form
          form={editForm}
          onValuesChange={handleEditInput}
          layout="vertical"
          labelAlign={'right'}
          onFinish={handleEditSubmit}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="name" label="Tên dự án" rules={[{ required: true, message: 'Vui lòng nhập' }]}>
                <Input className="h-[40px]" />
              </Form.Item>
            </Col>

            {/* if (typeof selectedProject?.industry === 'string') {
              selectedProject.industry = JSON.parse(selectedProject.industry);
            } */}
            <Col span={12}>
              <Form.Item name="industry" label="Lĩnh vực dự án" rules={[{ required: true, message: 'Vui lòng nhập' }]}>
                <Select
                  maxTagCount="responsive"
                  placeholder=""
                  className="h-[40px]"
                  mode="multiple"
                  showSearch
                  filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                >
                  {industryType.map((industry) => (
                    <Option key={industry.id} value={industry.id}>
                      {industry.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="start_date" label="Ngày bắt đầu" rules={[{ required: true, message: 'Vui lòng nhập' }]}>
                <DatePicker format="YYYY-MM-DD" placeholder="" disabledDate={disabledStartDate} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="end_date" label="Ngày kết thúc" placeholder="">
                <DatePicker format="YYYY-MM-DD" placeholder="" disabledDate={disabledDateEndDate} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="position_id"
                label="Vị trí đảm nhiệm"
                rules={[{ required: true, message: 'Vui lòng nhập' }]}
              >
                <Select placeholder="" className="h-[40px]">
                  {positions.map((position) => (
                    <Option key={position.id} value={position.id}>
                      {position.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="scale" label="Quy mô dự án" rules={[{ required: true, message: 'Vui lòng nhập' }]}>
                <Input className="h-[40px]" placeholder="Số MM" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="skill_ids" label="Ngôn ngữ lập trình">
                <Select
                  maxTagCount="responsive"
                  mode="multiple"
                  placeholder=""
                  className="h-[40px]"
                  defaultValue={(selectedProject?.skills || []).map((skill) => skill.id)}
                  showSearch
                  filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                >
                  {skillsDev.map((skill) => (
                    <Option key={skill.id} value={skill.id}>
                      {skill.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="other_skill" label="Ngôn ngữ khác">
                <Input className="h-[40px]" placeholder="Hàn, Nhật,..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="database" label="Database">
                <Input className="h-[40px]" placeholder="Mysql, PostgreSQL, MongoDB,..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="server" label="Server OS">
                <Input className="h-[40px]" placeholder="Ubuntu, CentOS, Redhat,..." />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="framework" label="Framework">
                <Input className="h-[40px]" placeholder="NodeJs, ReactJs, Laravel,..." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phase_type"
                label="Giai đoạn phát triển"
                rules={[{ required: true, message: 'Vui lòng nhập' }]}
              >
                <Select
                  maxTagCount="responsive"
                  mode="multiple"
                  placeholder=""
                  className="h-[40px]"
                  defaultValue={(selectedProject?.phase_type || []).map((phase) => parseInt(phase.id))}
                >
                  {phaseType.map((phase) => (
                    <Option key={phase.id} value={phase.id}>
                      {phase.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="content"
                label="Nội dung nghiệp vụ/ vai trò trong dự án"
                rules={[{ required: true, message: 'Please enter project content' }]}
              >
                <Input.TextArea rows={7} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Button type="default" onClick={() => setOpenModalEdit(false)}>
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};

export default Project;
