import React, { useEffect, useState } from 'react';
import { Modal, Button, Select, Form, Row, Col, Tag, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import iconDelete from 'assets/img/icons/delete.png';
import styles from './style.module.scss';
import classNames from 'classnames';
import { IoIosAdd } from 'react-icons/io';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from 'constants/StatusCode';
import { apiUpdateForeignLanguage } from 'api/profileEmployee';
import { apiDeleteForeignLanguage } from 'api/profileEmployee';

const { Option } = Select;

const ForeignLanguage = (props) => {
  const { uProfileUser, isLoading, userId, refetch } = props;
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const certificates = [
    { id: 1, type: 'TOEIC', level: 'Basic (10-215)' },
    { id: 2, type: 'TOEIC', level: 'Elementary (220-465)' },
    { id: 3, type: 'TOEIC', level: 'Intermediate (470-725)' },
    { id: 4, type: 'TOEIC', level: 'Upper Intermediate (730-900)' },
    { id: 5, type: 'TOEIC', level: 'Advanced (905-990)' },
    { id: 6, type: 'IELTS', level: 'Beginner (0-3.5)' },
    { id: 7, type: 'IELTS', level: 'Elementary (4.0-4.5)' },
    { id: 8, type: 'IELTS', level: 'Intermediate (5.0-5.5)' },
    { id: 9, type: 'IELTS', level: 'Upper Intermediate (6.0-6.5)' },
    { id: 10, type: 'IELTS', level: 'Advanced (7.0-7.5)' },
    { id: 11, type: 'IELTS', level: 'Proficient (8.0-9.0)' },
    { id: 12, type: 'TOELF', level: 'Beginner (0-31)' },
    { id: 13, type: 'TOELF', level: 'Elementary (32-34)' },
    { id: 14, type: 'TOELF', level: 'Intermediate (35-45)' },
    { id: 15, type: 'TOELF', level: 'Upper Intermediate (46-59)' },
    { id: 16, type: 'TOELF', level: 'Advanced (60-78)' },
    { id: 17, type: 'TOELF', level: 'Proficient (79-120)' },
    { id: 18, type: 'JLPT', level: 'N5' },
    { id: 19, type: 'JLPT', level: 'N4' },
    { id: 20, type: 'JLPT', level: 'N3' },
    { id: 21, type: 'JLPT', level: 'N2' },
    { id: 22, type: 'JLPT', level: 'N1' },
    { id: 23, type: 'TOPIK', level: 'TOPIK 1 (80-139)' },
    { id: 24, type: 'TOPIK', level: 'TOPIK 2 (>140)' },
    { id: 25, type: 'TOPIK', level: 'TOPIK 3 (120-149)' },
    { id: 26, type: 'TOPIK', level: 'TOPIK 4 (150-189)' },
    { id: 27, type: 'TOPIK', level: 'TOPIK 5 (190-229)' },
    { id: 28, type: 'TOPIK', level: 'TOPIK 6 (>230)' },
    { id: 29, type: 'HSK', level: 'HSK 1' },
    { id: 30, type: 'HSK', level: 'HSK 2' },
    { id: 31, type: 'HSK', level: 'HSK 3' },
    { id: 32, type: 'HSK', level: 'HSK 4' },
    { id: 33, type: 'HSK', level: 'HSK 5' },
    { id: 34, type: 'HSK', level: 'HSK 6' },
  ];
  const [level, setLevel] = useState([]);
  const certificateTypes = [...new Set(certificates.map((cert) => cert.type))];
  const [newCertificates, setNewCertificates] = useState([{ id: 1, type: '', level: '' }]);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [updatedCertificates, setUpdatedCertificates] = useState([]);

  useEffect(() => {
    let selectedIds = uProfileUser?.user_information?.language_id?.split(',').map((id) => Number(id));
    setSelectedCertificates(certificates.filter((cert) => selectedIds?.includes(cert.id)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uProfileUser]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const certificateIds = selectedCertificates.map((cert) => cert.id);
    certificateIds.push(...updatedCertificates);
    const response = await apiUpdateForeignLanguage({ language_id: certificateIds });
    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      return form.setFields(response.data.messages);
    }

    if (response?.status === HTTP_OK) {
      refetch();
      setNewCertificates([]);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewCertificates([]);
  };

  const handleAddCertificate = () => {
    setNewCertificates([...newCertificates, { id: newCertificates.length + 1, type: '', level: '' }]);
  };

  const handleCertificateChange = (id, field, value) => {
    const updated = newCertificates.map((cert) => (cert.id === id ? { ...cert, [field]: value } : cert));
    setUpdatedCertificates(updated.map((cert) => cert.level));
    setNewCertificates(updated);
  };

  const handleCertificateTypeChange = (id, field, value) => {
    setLevel(certificates.filter((cert) => cert.type === value));
    form.setFieldsValue({ [`level_${id}`]: '' });
  };

  const handleDeleteCertificate = async (id) => {
    const response = await apiDeleteForeignLanguage(id);
    setSelectedCertificates(selectedCertificates.filter((cert) => cert.id !== id));

    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      return;
    }

    if (response?.status === HTTP_OK) {
      refetch();
    }
  };

  return (
    <>
      <div className={styles.profileBoxInfo}>
        <Row className={styles.rowTitle}>
          <Col span={22}>
            <div className={styles.skillSheetTitle}>
              <i className="fas fa-graduation-cap"></i> Ngôn ngữ
            </div>
          </Col>
          <Col span={2}>
            <div className={styles.editDeleteButton}>
              <IoIosAdd onClick={showModal} />
            </div>
          </Col>
        </Row>

        {(isLoading || !userId) && (
          <p className="error-no-data">
            <Spin className="mt-3" />
          </p>
        )}
        {!isLoading && userId && (
          <Row className={classNames(`${styles.rowTitle} foreignLanguage`)}>
            <Col span={24} className={classNames(`${styles.skillBox} listCertificate`)}>
              {selectedCertificates.map((cert) => (
                <Tag key={cert.id} className="tagCertificate">
                  {cert.type}: {cert.level}
                  <img
                    alt={iconDelete}
                    src={iconDelete}
                    onClick={() => handleDeleteCertificate(cert.id)}
                    style={{ width: '15px', height: '16px', marginLeft: '5px' }}
                  />
                </Tag>
              ))}
            </Col>
          </Row>
        )}
      </div>

      <Modal
        title="Ngôn ngữ"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        okButtonProps={{ disabled: false }}
        cancelText={t('common.cancel')}
        okText={t('infoEmployee.saveEdit')}
        className={styles.modalSkill}
      >
        <Form form={form} layout="vertical">
          {newCertificates?.map((cert) => (
            <Row key={cert.id} gutter={16}>
              <Col span={12}>
                <Form.Item
                  name={`type_${cert.id}`}
                  rules={[{ required: true, message: 'Vui lòng nhập loại chứng chỉ' }]}
                >
                  <Select
                    placeholder="Loại chứng chỉ *"
                    onChange={(value) => handleCertificateTypeChange(cert.id, 'level', value)}
                  >
                    {certificateTypes.map((type) => (
                      <Option key={type} value={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={`level_${cert.id}`} rules={[{ required: true, message: 'Vui lòng nhập cấp độ' }]}>
                  <Select placeholder="Cấp độ *" onChange={(value) => handleCertificateChange(cert.id, 'level', value)}>
                    {level.map((cert) => (
                      <Option key={cert.id} value={cert.id}>
                        {cert.level}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ))}
        </Form>
        <Button className={styles.addMore} type="dashed" icon={<PlusOutlined />} block onClick={handleAddCertificate}>
          Thêm chứng chỉ
        </Button>
      </Modal>
    </>
  );
};

export default ForeignLanguage;
