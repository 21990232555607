import React from 'react';
import { Col, Row } from 'reactstrap';
import iconCareer from '../../../../assets/img/icons/career.png';
import styles from './../style.module.scss';
import classNames from 'classnames';


const SkillSheet = (props) => {
  return (
    <>
      <div className={styles.profileBoxContract}>
        <Row className={styles.rowTitle}>
          <Col xs="10">
            <div className={classNames(`${styles.profileTitle} w-100`)}>
              <img alt={iconCareer} className={styles.imgTitle} src={iconCareer} />
              Tổng quan
            </div>
          </Col>
          <Col xs="2"></Col>
        </Row>
        <div>
        </div>
      </div>
    </>
  );
};

export default SkillSheet;
