import 'swiper/swiper.scss';
import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import LayoutProfile from 'components/LayoutProfile';
import Loading from 'components/Loading';
import NavProfile from '../Nav.js';
import { ThemContext } from 'layouts/Member';
import { LOADING_MD } from 'constants/index.js';
import { useProfileUser } from 'hook/useProfileEmployee';
import MainSkill from './MainSkill';
import { HiPencil } from 'react-icons/hi';
import Project from './Project.js';
import ModalComponent from 'components/Modal';
import { Box, TextField } from '@mui/material';
import ButtonComponent from 'components/Buttons';
import { HTTP_UNPROCESSABLE_ENTITY } from 'constants/StatusCode';
import { HTTP_OK } from 'constants/StatusCode';
import { apiUpdateSkillSheetOverview } from 'api/profileEmployee';
import AlertComponent from 'components/Alert';
import Achievement from './Certificates';
import Skill from './Skill';
import iconInfo from '../../../../assets/img/icons/info.png';
import ForeignLanguage from './ForeignLanguage';

const SkillSheet = (props) => {
  const { t } = useTranslation();
  const userId = useContext(ThemContext);
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const [message, setMessage] = useState('');
  const { data: uProfileUser, isLoading: isLoadingProfileUser, refetch } = useProfileUser('', userId);

  const marketType = uProfileUser?.data?.user_information?.market_type
    ?.map((item) => {
      if (item === '1') {
        return {
          id: 1,
          name: 'Thị trường Nhật Bản',
        };
      } else if (item === '2') {
        return {
          id: 2,
          name: 'Thị trường Global',
        };
      } else {
        return undefined;
      }
    })
    .filter((item) => item !== undefined);

  const [isShowAlert, setShowAlert] = useState(false);
  const [formSubmit, setFormSubmit] = useState({});
  const [stateButton, setStateButton] = useState(false);

  useEffect(() => {
    setFormSubmit({
      market_type: uProfileUser?.data?.user_information?.market_type
        ? uProfileUser?.data?.user_information?.market_type
        : [],
      overview: uProfileUser?.data?.user_information?.overview ? uProfileUser?.data?.user_information?.overview : '',
    });
  }, [uProfileUser]);

  const handleInput = (e, nameField) => {
    setFormSubmit({
      ...formSubmit,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    setFormSubmit({
      ...formSubmit,
      market_type: [...new Set(formSubmit.market_type)],
    });
    event.preventDefault();
    setStateButton(true);

    const data = {
      ...formSubmit,
    };

    let response = {};
    response = await apiUpdateSkillSheetOverview(data);
    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      setMessage('Vui lòng nhập đầy đủ thông tin thị trường và giới thiệu tổng quan');
      setShowAlert(true);
      setStateButton(false);
      return;
    }

    if (response?.data?.status === HTTP_OK) {
      setShowModalEdit(false);
      setMessage(response.data.messages);
      setStateButton(false);
      refetch();
    }
  };

  const handleClickEdit = () => {
    setShowModalEdit(true);
  };

  return (
    <>
      <div className={styles.containerRoot}>
        <div className={styles.rowInfo}>
          {(isLoadingProfileUser || !userId) && <Loading addClass="mt-10" type={LOADING_MD} />}
          {uProfileUser && (
            <>
              <Row>
                <Col md="3">
                  <LayoutProfile uProfileUser={uProfileUser?.data} />
                </Col>
                <Col md="9">
                  <NavProfile />
                  <div className={styles.contentRoot}>
                    <Row>
                      <Col md="12">
                        <div className={`${styles.contractHistory} ${styles.profileBoxInfo}`}>
                          <Row className={styles.rowTitle}>
                            <Col xs="12">
                              <Row className={styles.rowBorder}>
                                <Col xs="11">
                                  <div className={classNames(`${styles.profileTitle} w-100`)}>
                                    <img alt={iconInfo} src={iconInfo} />
                                    Tổng Quan
                                  </div>
                                </Col>
                                <Col xs="1" style={{ padding: '0px' }}>
                                  <div className={classNames(`${styles.editDeleteButton} mr--15}`)}>
                                    <HiPencil onClick={handleClickEdit} />
                                  </div>
                                </Col>
                              </Row>
                              <div className="d-flex">
                                {marketType &&
                                  marketType.length > 0 &&
                                  marketType.map((item, index) => (
                                    <div className={styles.marketType}>
                                      <span>{item.name}</span>
                                    </div>
                                  ))}
                              </div>
                              <p style={{ margin: '15px 0' }}>{uProfileUser?.data?.user_information?.overview}</p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <MainSkill
                    userId={userId}
                    isLoading={isLoadingProfileUser}
                    uProfileUser={uProfileUser?.data}
                    refetch={refetch}
                  />
                  <ForeignLanguage
                    userId={userId}
                    isLoading={isLoadingProfileUser}
                    uProfileUser={uProfileUser?.data}
                    refetch={refetch}
                  />
                  <Achievement uProfileUser={uProfileUser?.data} userId={userId}></Achievement>
                  <Skill uProfileUser={uProfileUser?.data} userId={userId}></Skill>
                  <Project
                    userId={userId}
                    isLoading={isLoadingProfileUser}
                    uProfileUser={uProfileUser?.data}
                    refetch={refetch}
                  />
                </Col>
              </Row>
            </>
          )}
          {userId && !isLoadingProfileUser && !uProfileUser && (
            <p className={classNames(`${styles.loading} text-center m-0`)}>{t('common.notData')}</p>
          )}
        </div>
      </div>
      <ModalComponent
        toggle={() => setShowModalEdit(!isShowModalEdit)}
        isShowModal={isShowModalEdit}
        className="w-64"
        title="Tổng quan"
        styles={{ width: '700px' }}
      >
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <>
            {/* <h6 className={styles.label}>Nhân sự thị trường</h6> */}
            {/* <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: '#fea628',
                    '&.Mui-checked': {
                      color: '#fea628',
                    },
                  }}
                />
              }
              checked={formSubmit.market_type?.includes('1')}
              value="1"
              onChange={(e) => {
                setFormSubmit({
                  ...formSubmit,
                  market_type: formSubmit.market_type?.includes('1')
                    ? formSubmit.market_type?.filter((item) => item !== '1')
                    : [...new Set(formSubmit.market_type.concat('1'))],
                });
              }}
              label="Nhật Bản"
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: '#fea628',
                    '&.Mui-checked': {
                      color: '#fea628',
                    },
                  }}
                />
              }
              checked={formSubmit.market_type?.includes('2')}
              value="2"
              onChange={(e) => {
                setFormSubmit({
                  ...formSubmit,
                  market_type: formSubmit.market_type?.includes('2')
                    ? formSubmit.market_type?.filter((item) => item !== '2')
                    : [...new Set(formSubmit.market_type.concat('2'))],
                });
              }}
              label="Global"
            /> */}
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={7}
              placeholder="Giới thiệu tổng quan về kinh nghiệm, kỹ năng làm việc *"
              name="overview"
              defaultValue={formSubmit?.overview}
              onInput={handleInput}
              error={formSubmit.error_overview}
              className="inputOverview"
            />
          </>
          <Row className="mt-4">
            <Col className="md-6">
              <ButtonComponent
                text={t('common.cancel')}
                addClass="w-100 btn-bg-yellow2"
                handleClick={() => setShowModalEdit(false)}
              />
            </Col>
            <Col className="md-6">
              <ButtonComponent
                text={t('infoEmployee.saveEdit')}
                addClass="w-100"
                handleClick={() => setShowModalEdit(true)}
                type="submit"
                hasDisabled={stateButton}
              />
            </Col>
          </Row>
        </Box>
      </ModalComponent>
      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};
export default SkillSheet;
