import React from 'react';
import { useTranslation } from 'react-i18next';

import NavComponent from './../../../components/Nav';
import styles from './style.module.scss';

const Nav = () => {
  const { t } = useTranslation();
  const routes = [
    {
      path: `/employee/profile`,
      name: t('infoEmployee.tabEmployeeInfo'),
      tab: 1,
    },
    {
      path: `/employee/contract`,
      name: t('infoEmployee.contractInfo'),
      tab: 2,
    },
    {
      path: `/employee/skill-sheet`,
      name: t('infoEmployee.skillSheet'),
      tab: 3,
    },
  ];

  return (
    <div className={styles.navMenuProfile}>
      <NavComponent routes={routes} />
    </div>
  );
};

export default Nav;
