import Dashboard from 'views/pages/Dashboard';

import ProfileUser from 'views/pages/ProfileEmployees/Profile';
import ContractUser from 'views/pages/ProfileEmployees/Contract';
import SkillSheet from 'views/pages/ProfileEmployees/SkillSheet';
import HandbookEmployee from 'views/pages/Company/HandbookEmployee';
import CompanyEmployees from 'views/pages/Company/CompanyEmployees';

import ListAsset from 'views/pages/Assets/ListAssets';
import ListAssetRequest from 'views/pages/Assets/ListAssetRequest';

import DepartmentDetail from 'views/pages/Company/DeparmentDetail';
import MemberDetail from 'views/pages/Company/MemberDetail';

import Timekeeping from 'views/pages/TimeKeeping/TimeSheets';
import Schedule from 'views/pages/TimeKeeping/Schedule';
import ListOt from 'views/pages/TimeKeeping/ListOT';
import ListRequests from 'views/pages/TimeKeeping/ListRequests';
import RequestOvertime from 'views/pages/TimeKeeping/RequestOT';
import PageNotAccess from 'views/pages/PageNotAccess';
// import ListProject from 'views/pages/Projects/ListProject';
import DailyReport from 'views/pages/Projects/DailyReport';
import CalendarReport from 'views/pages/Projects/CalendarReport';

var routesMenu = [
  {
    path: '/index',
    name: 'menu.dashboard',
    icon: '',
    component: Dashboard,
  },
  {
    path: '/employee/profile',
    name: 'menu.memberInformation',
    icon: '',
    component: ProfileUser,
  },
  {
    path: '/assets/list-assets',
    name: 'menu.asset',
    icon: '',
    component: ListAsset,
  },
  // {
  //   path: '/project/list-project',
  //   name: 'menu.project',
  //   icon: '',
  //   component: ListProject,
  // },
  {
    path: '/project/daily-report',
    name: 'menu.project',
    icon: '',
    component: DailyReport,
  },
  {
    path: '/timekeeping/time-sheets',
    name: 'menu.timesheet',
    icon: '',
    component: Timekeeping,
  },
  {
    path: '/timekeeping/schedule',
    name: 'menu.timesheet',
    icon: '',
    component: Schedule,
  },
  {
    path: '/companies/handbook',
    name: 'menu.company',
    icon: '',
    component: HandbookEmployee,
  },
  {
    path: '/companies/employees',
    name: 'menu.companyEmployees',
    icon: '',
    component: CompanyEmployees,
  },
];

var routeNav = [
  {
    path: '/employee/profile',
    name: 'profile',
    icon: '',
    component: ProfileUser,
    class: 'hidden',
  },
  {
    path: '/employee/contract',
    name: 'profile',
    icon: '',
    component: ContractUser,
    class: 'hidden',
  },
  {
    path: '/employee/skill-sheet',
    name: 'profile',
    icon: '',
    component: SkillSheet,
    class: 'hidden',
  },
  {
    path: '/department/detail/:departmentId',
    name: 'detailDepartment',
    icon: '',
    component: DepartmentDetail,
    class: 'hidden',
  },
  {
    path: '/member/detail/:userId',
    name: 'memberDetail',
    icon: '',
    component: MemberDetail,
    class: 'hidden',
  },
  {
    path: '/assets/list-request',
    name: 'assets.listRequest',
    icon: '',
    component: ListAssetRequest,
    class: 'hidden',
  },
  {
    path: '/timekeeping/list-ot',
    name: 'menu.listOt',
    icon: '',
    component: ListOt,
    class: 'hidden',
  },
  {
    path: '/timekeeping/list-requests',
    name: 'menu.listRequets',
    icon: '',
    component: ListRequests,
    class: 'hidden',
  },
  {
    path: '/timekeeping/request-ot',
    name: 'menu.requestOvertime',
    icon: '',
    component: RequestOvertime,
    class: 'hidden',
  },
  // {
  //   path: '/project/daily-report',
  //   name: 'project.dailyReport',
  //   icon: '',
  //   component: DailyReport,
  //   class: 'hidden',
  // },
  {
    path: '/project/calendar-report',
    name: 'project.calendar',
    icon: '',
    component: CalendarReport,
    class: 'hidden',
  },
  {
    path: '/page-not-access',
    name: 'menu.pageNotAccess',
    icon: '',
    component: PageNotAccess,
    class: 'hidden',
  },
];

const routes = [...routesMenu, ...routeNav];

export default routes;
