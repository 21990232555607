import React, { useState, useEffect } from 'react';
import { Col, Row, Input, Form, Label } from 'reactstrap';
import { IoIosAdd } from 'react-icons/io';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Box } from '@mui/material';

import iconDelete from 'assets/img/icons/delete.png';
import iconStar from 'assets/img/icons/star.png';
import iconNext from 'assets/img/icons/next.png';
import iconPrev from 'assets/img/icons/prev.png';

import styles from './style.module.scss';
import ModalComponent from 'components/Modal';
import ButtonComponent from 'components/Buttons';
import AlertComponent from 'components/Alert';
import { apiUpdatesKill, apiDeleteKill } from 'api/profileEmployee.js';
import { useListPositionKill, useListKillByPosition } from 'hook/useProfileEmployee.js';
import { HTTP_OK, HTTP_NOT_ACCESS } from 'constants/StatusCode';
import { USE_USER_PROFILE } from 'constants/ProfileEmployee.js';
import { NO_DATA } from 'constants/index.js';

SwiperCore.use([Navigation]);

const Skill = (props) => {
  const { uProfileUser, userId } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [positionId, setPositionId] = useState(null);
  const [isBtnSubmit, setIsBtnSubmit] = useState(false);
  const [isShowModalSkill, setShowModalSkill] = useState(false);
  const [isShowAlert, setShowAlert] = useState(false);
  const [isErrorSkill, setErrorSkill] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { data: listKillByPosition } = useListKillByPosition(positionId);
  const { data: listPositionSkill } = useListPositionKill();
  const [dataSkill, setDataSkill] = useState([]);

  const resetSkill = (uProfileUser) => {
    setErrorSkill(false)
    if (uProfileUser) {
      setDataSkill(uProfileUser?.user_skill);
      if (uProfileUser.data?.user_information) {
        setPositionId(uProfileUser.data.user_information.position_id);
      }
    }
  };

  useEffect(() => {
    if (uProfileUser && uProfileUser.data) {
      resetSkill(uProfileUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uProfileUser]);

  useEffect(() => {
    if (!isShowModalSkill) {
      resetSkill(uProfileUser);
      setErrorSkill(false);
      setError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uProfileUser, isShowModalSkill]);

  useEffect(() => {
    if (listPositionSkill && !positionId) {
      setPositionId(listPositionSkill[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPositionSkill, positionId]);

  const handleSelectPositionSkill = (value) => {
    setErrorSkill(false)
    setPositionId(value.target.value);
  };

  const handleSelectSkill = (event, nameSkill, index) => {
    setError('');
    setErrorSkill(false);
    const { checked, value } = event.target;
    if (checked) {
      const userSkills = JSON.parse(JSON.stringify(dataSkill));
      userSkills.splice(index, 0, {
        skill_id: value,
        skill: nameSkill,
        experience: '',
        months_experience: '',
      });
      setDataSkill(userSkills);
    } else {
      const userSkills = [];
      dataSkill.forEach((item) => {
        if (Number(item.skill_id) !== Number(value)) {
          userSkills.push(item);
        }
      });
      setDataSkill([...userSkills]);
    }
  };

  const onChangeYearAndMonth = (event, skillId) => {
    const { name, value } = event.target
    if (skillId) {
      const cloneDataSkill = JSON.parse(JSON.stringify(dataSkill))
      const skillIndex = cloneDataSkill.findIndex((item) => Number(item.skill_id) === Number(skillId))
      if (skillIndex !== -1) {
        cloneDataSkill[skillIndex][name] = value
        setDataSkill(cloneDataSkill)
      }
    }
  };

  const handleSubmitSkill = async (event) => {
    event.preventDefault();
    setIsBtnSubmit(true);
    if (positionId) {
      if (dataSkill.length === NO_DATA) {
        setErrorSkill(true)
        setIsBtnSubmit(false);
        return;
      }
      const response = await apiUpdatesKill(userId, { data: dataSkill });
      setIsBtnSubmit(false);
      if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
        setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
        setShowAlert(true);
        setShowModalSkill(!isShowModalSkill);
        setError('');
      }
      if (response && response.data.status === HTTP_OK) {
        setShowModalSkill(!isShowModalSkill);
        setError('');
        await queryClient.invalidateQueries(USE_USER_PROFILE);
        if (dataSkill?.some((skill) => skill?.id)) {
          setMessage(t('common.updateSuccessfully'));
        } else {
          setMessage(t('common.createSuccessfully'));
        }
        setShowAlert(true);
      } else {
        setError(response.data.messages);
      }
    } else {
      setErrorSkill(true);
      setIsBtnSubmit(false);
    }
  };

  const handleDeleteSkill = async (id) => {
    confirmAlert({
      title: t('common.doYouWantToDeletedData?'),
      buttons: [
        {
          label: t('common.cancel'),
          className: 'btn-alert-cancel',
        },
        {
          label: t('common.ok'),
          className: 'btn-alert-ok',
          onClick: async () => {
            const response = await apiDeleteKill(id);
            if (response?.data?.status === HTTP_NOT_ACCESS && response?.data?.is_edit) {
              setMessage(t('common.pleaseCheckAgainYouAreNotAuthorizedToUseThisFeature'));
              setShowAlert(true);
            }
            if (response.data.status === HTTP_OK) {
              queryClient.invalidateQueries(USE_USER_PROFILE);
              setMessage(response.data.messages);
              setShowAlert(true);
            }
          },
        },
      ],
    });
  };

  const getLabelSkill = (item) => {
    let label;
    if (item.experience) {
      label = item.skill + ` (${item.experience} ${t('common.year')})`
      if (item.months_experience) {
        label = item.skill + ` (${item.experience} ${t('common.year')} ${item.months_experience} ${t('common.months')})`
      }
    } else {
      label = item.skill + ` ${item.months_experience ? `(${item.months_experience} ${t('common.months')})` : ''}`
    }
    return label
  };

  return (
    <>
      <div className={styles.profileBoxSkill}>
        <Row className={styles.rowTitle}>
          <Col xs="11">
            <div className={styles.profileTitle}>
              <img alt={iconStar} className={styles.imgTitle} src={iconStar} />
              {t('infoEmployee.titleSkill')}
            </div>
          </Col>
          <Col xs="1">
            {uProfileUser?.user_skill?.length !== NO_DATA && (
              <div className={classNames(`${styles.editDeleteButton} mr--15`)} onClick={() => setShowModalSkill(true)}>
                <IoIosAdd />
              </div>
            )}
          </Col>
        </Row>
        <div>
          {uProfileUser && (uProfileUser?.user_skill?.length === NO_DATA) | (uProfileUser?.user_skill === null) ? (
            <div className={styles.contentSkill}>
              <div className={styles.achievementNull}>
                <div className={`${styles.editDeleteButton} `} onClick={() => setShowModalSkill(true)}>
                  <IoIosAdd />
                </div>
                <div className={styles.profileContentInfoAlert}>{t('infoEmployee.addSkill')}</div>
              </div>
            </div>
          ) : (
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={15}
              slidesPerView={3}
              navigation={{
                prevEl: '.prevEdu',
                nextEl: '.nextEdu',
              }}
              breakpoints={{
                1366: {
                  slidesPerView: 6,
                },
                1024: {
                  slidesPerView: 5,
                },
                768: {
                  slidesPerView: 5,
                },
                320: {
                  slidesPerView: 2,
                },
              }}
              className={styles.salaryBoxParent}
            >
              {uProfileUser &&
                uProfileUser?.user_skill?.map((val, index) => (
                  <SwiperSlide key={index}>
                    <div key={index} className={`${styles.labelSkill} `}>
                      <span>{getLabelSkill(val)}</span>
                      <img alt={iconDelete} src={iconDelete} onClick={() => handleDeleteSkill(val.id)} />
                    </div>
                  </SwiperSlide>
                ))}
              <Box hidden={uProfileUser?.user_skill?.length <= 6}>
                <div className={`${styles.prevButton} prevEdu`} style={{ zIndex: 99999 }}>
                  <img alt={iconPrev} src={iconPrev} />
                </div>
                <div className={`${styles.nextButton} nextEdu`} style={{ zIndex: 99999 }}>
                  <img alt={iconNext} src={iconNext} />
                </div>
              </Box>
            </Swiper>
          )}
        </div>
      </div>
      <ModalComponent
        toggle={() => setShowModalSkill(!isShowModalSkill)}
        isShowModal={isShowModalSkill}
        title={
          uProfileUser?.user_skill?.length
            ? t('employees.updateWorkingSkills')
            : t('employees.addWorkingSkills')
        }
      >
        <Form onSubmit={handleSubmitSkill}>
          <div className="ct-checkbox">
            {listPositionSkill?.length > 0 ? (
              <>
                <Row>
                  <Col className="col-md-12">
                    <Input
                      type="select"
                      name="position"
                      id="exampleSelect"
                      onChange={handleSelectPositionSkill}
                      value={positionId}
                    >
                      {listPositionSkill?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={4}>
                    <p className="title-body mt-4">{t('employees.filterSkill')}</p>
                  </Col>
                  <Col md={8}>
                    <p className="title-body mt-4">{t('employees.experience')}</p>
                  </Col>
                  {listKillByPosition &&
                    listKillByPosition?.map((skill, index) => (
                      <>
                        <Col md={4}>
                          <div className={`custom-control custom-checkbox mb-3 ${styles.checkboxCustom}`}>
                            <Input
                              className={`custom-control-input`}
                              id={'skill' + skill.id}
                              type="checkbox"
                              value={skill.id}
                              name="skills"
                              checked={
                                dataSkill.indexOf(dataSkill.find((x) => Number(x.skill_id) === Number(skill.id))) < 0
                                  ? false
                                  : true
                              }
                              onChange={(e) => handleSelectSkill(e, skill.name, index)}
                            />
                            <Label
                              className="custom-control-label fs-14 text-bold color-charcoal"
                              htmlFor={'skill' + skill.id}
                            >
                              {skill.name}
                            </Label>
                          </div>
                        </Col>
                        <Col md={8}>
                          <Row>
                            <Col md={6}>
                              <Input
                                type="text"
                                name="experience"
                                value={
                                  dataSkill &&
                                  dataSkill
                                    .map((item) => {
                                      if (Number(item.skill_id) === Number(skill.id)) {
                                        return item.experience;
                                      }
                                      return null;
                                    })
                                    .filter((it) => it)
                                }
                                maxLength={2}
                                onChange={(e) => onChangeYearAndMonth(e, skill.id)}
                                placeholder={t('common.year')}
                                disabled={
                                  dataSkill.indexOf(dataSkill.find((x) => Number(x.skill_id) === Number(skill.id))) < 0
                                    ? true
                                    : false
                                }
                                className="text-right"
                              />
                              {error && error[`data.${index}.experience`]?.length ? (
                                <p className="error">
                                  {error[`data.${index}.experience`]?.toString()}
                                </p>
                              ) : null}
                            </Col>
                            <Col md={6}>
                              <Input
                                type="text"
                                name="months_experience"
                                value={
                                  dataSkill &&
                                  dataSkill
                                    .map((item) => {
                                      if (Number(item.skill_id) === Number(skill.id)) {
                                        return item.months_experience;
                                      }
                                      return null;
                                    })
                                    .filter((it) => it)
                                }
                                maxLength={2}
                                onChange={(e) => onChangeYearAndMonth(e, skill.id)}
                                placeholder={t('common.months')}
                                disabled={
                                  dataSkill.indexOf(dataSkill.find((x) => Number(x.skill_id) === Number(skill.id))) < 0
                                    ? true
                                    : false
                                }
                                className="text-right"
                              />
                              {error && error[`data.${index}.months_experience`]?.length ? (
                                <p className="error">
                                  {error[`data.${index}.months_experience`]?.toString()}
                                </p>
                              ) : null}
                            </Col>
                          </Row>
                        </Col>
                      </>
                    ))}
                  <Col className="col-md-12">
                    <p className={`${styles.messageError} error`}>{isErrorSkill && t('common.thisIsObligation')}</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Row>
                    <Col className="md-6">
                      <ButtonComponent
                        text={t('common.cancel')}
                        addClass="w-100 btn-bg-yellow2"
                        handleClick={() => setShowModalSkill(!isShowModalSkill)}
                      />
                    </Col>
                    <Col className="md-6">
                      <ButtonComponent
                        text={uProfileUser?.user_skill?.length ? t('common.update') : t('common.add')}
                        addClass="w-100"
                        type="submit"
                        hasDisabled={!listKillByPosition?.length || isBtnSubmit}
                        isLoading={isBtnSubmit}
                      />
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div className="error-no-data">
                <p>{t('common.unableToGetData')}</p>
              </div>
            )}
          </div>
        </Form>
      </ModalComponent>

      <AlertComponent toggle={() => setShowAlert(!isShowAlert)} isShowAlert={isShowAlert}>
        {message}
      </AlertComponent>
    </>
  );
};

export default Skill;
