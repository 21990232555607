const RouteMenu = (userId) => {
  return [
    {
      path: '/index',
      name: 'menu.dashboard',
      icon: '',
    },
    {
      path: `/employee/profile`,
      name: 'menu.memberInformation',
      icon: '',
      links: ['/employees/profile/*', '/employee/contract', '/employee/skill-sheet'],
    },
    {
      path: `/assets/list-assets`,
      name: 'menu.asset',
      links: ['/assets/list-request'],
    },
    // {
    //   path: `/project/list-project`,
    //   name: 'menu.project',
    //   icon: '',
    //   links: ['/project/daily-report', '/project/calendar-report'],
    // },
    {
      path: `/project/calendar-report`,
      name: 'menu.project',
      icon: '',
      links: [`/project/daily-report`],
    },
    {
      path: '/timekeeping/time-sheets',
      name: 'menu.timekeeping',
      icon: '',
      links: ['/timekeeping/list-ot', '/timekeeping/list-requests', '/timekeeping/request-ot', '/timekeeping/schedule'],
    },
    {
      path: '/companies/handbook',
      name: 'menu.company',
      icon: '',
      links: ['/department/detail/*', '/companies/employees', '/member/detail/*'],
    },
  ];
};

export default RouteMenu;
