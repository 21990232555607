import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Form, Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { HiPencil } from 'react-icons/hi';
import { HTTP_UNPROCESSABLE_ENTITY, HTTP_OK } from 'constants/StatusCode';
import { apiUpdateSkillSheetInfo } from 'api/profileEmployee';
import styles from './style.module.scss';
import iconStar from 'assets/img/icons/star.png';

const MainSkill = (props) => {
  const { uProfileUser, isLoading, userId, refetch } = props;
  const { t } = useTranslation();
  const [isShowModalEdit, setShowModalEdit] = useState(false);
  const [formSubmit, setFormSubmit] = useState({});
  const [stateButton, setStateButton] = useState(false);
  const [form] = Form.useForm();

  const handleInput = (changedValues, allValues) => {
    setFormSubmit(allValues);
  };

  useEffect(() => {
    setFormSubmit({
      expertise: uProfileUser?.user_information?.expertise || '',
      technique: uProfileUser?.user_information?.technique || '',
      main_task: uProfileUser?.user_information?.main_task || '',
    });
    form.setFieldsValue({
      expertise: uProfileUser?.user_information?.expertise || '',
      technique: uProfileUser?.user_information?.technique || '',
      main_task: uProfileUser?.user_information?.main_task || '',
    });
  }, [uProfileUser, form]);

  const handleSubmit = async () => {
    setStateButton(true);

    const data = {
      ...formSubmit,
    };

    let response = {};
    response = await apiUpdateSkillSheetInfo(data);
    if (response?.data?.status === HTTP_UNPROCESSABLE_ENTITY && response?.data?.messages) {
      setFormSubmit({
        ...formSubmit,
      });

      setStateButton(false);
      return;
    }

    if (response?.data?.status === HTTP_OK) {
      setShowModalEdit(false);
      setStateButton(false);
      refetch();
    }
  };

  const handleClickEdit = () => {
    setShowModalEdit(true);
  };

  return (
    <>
      <div className={styles.profileBoxInfo}>
        <Row className={styles.rowTitle}>
          <Col span={22}>
            <div className={styles.skillSheetTitle}>
              <img alt={iconStar} className={styles.imgTitle} src={iconStar} />
              Kỹ năng thế mạnh
            </div>
          </Col>
          <Col span={2}>
            <div className={styles.editDeleteButton}>
              <HiPencil onClick={handleClickEdit} />
            </div>
          </Col>
        </Row>

        {(isLoading || !userId) && (
          <p className="error-no-data">
            <Spin className="mt-3" />
          </p>
        )}
        {!isLoading && userId && (
          <Row className={styles.rowTitle}>
            <Col span={24} className={styles.skillBox}>
              {uProfileUser?.user_information?.expertise && (
                <p className={styles.skillItem}>Lĩnh vực chuyên môn: {uProfileUser?.user_information?.expertise}</p>
              )}
              {uProfileUser?.user_information?.technique && (
                <p className={styles.skillItem}>Kỹ năng chuyên môn: {uProfileUser?.user_information?.technique}</p>
              )}
              {uProfileUser?.user_information?.main_task && (
                <p className={styles.skillItem}>Nghiệp vụ chuyên môn: {uProfileUser?.user_information?.main_task}</p>
              )}
            </Col>
          </Row>
        )}
      </div>
      <Modal
        title="Kỹ năng thế mạnh"
        visible={isShowModalEdit}
        onCancel={() => setShowModalEdit(false)}
        onOk={handleSubmit}
        okButtonProps={{ disabled: stateButton }}
        cancelText={t('common.cancel')}
        okText={t('infoEmployee.saveEdit')}
        className={styles.modalSkill}
      >
        <Form form={form} onValuesChange={handleInput} layout="vertical">
          <Form.Item
            label="Chuyên môn"
            name="expertise"
            rules={[
              {
                max: 225,
                message: 'Input cannot exceed 225 characters!',
              },
            ]}
          >
            <Input placeholder="Phát triển web, phát triển ứng dụng,..." />
          </Form.Item>
          <Form.Item
            label="Kỹ thuật"
            name="technique"
            rules={[
              {
                max: 225,
                message: 'Input cannot exceed 225 characters!',
              },
            ]}
          >
            <Input placeholder="C#, .NET, Javascript,..." />
          </Form.Item>
          <Form.Item
            label="Nghiệp vụ chính"
            name="main_task"
            rules={[
              {
                max: 225,
                message: 'Input cannot exceed 225 characters!',
              },
            ]}
          >
            <Input placeholder="CRM, ERP, ..." />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MainSkill;
